<template>
  <b-container>
    <b-row class="mb-4">
      <b-col class="mb-2 mb-md-0" cols="4">
        <h1 class="font-weight-bold mb-0 text-primary">Cash Up</h1>
      </b-col>
      <b-col cols="8">
        <b-form-datepicker v-model="date" @input="doDataFetch"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card class="p-3" no-body>
          <b-row>
            <b-col>
              <b-table-simple class="mb-0" responsive>
                <b-thead>
                  <b-tr>
                    <b-th></b-th>
                    <b-th>Payments</b-th>
                    <b-th>Cash In</b-th>
                    <b-th>Refunds</b-th>
                    <b-th>Petty Taken</b-th>
                    <b-th>Expected Out</b-th>
                    <b-th>Actual Out</b-th>
                    <b-th>Float</b-th>
                    <b-th>Discrepancies</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="singleIsPopulated">
                  <b-tr
                    v-for="(paymentMethod, paymentMethodInd) in paymentMethods"
                    :key="`table-method-${paymentMethodInd}`"
                    :variant="tableVariationStatus(paymentMethod)">
                    <b-th>{{ paymentMethod }}</b-th>
                    <b-td>{{ single[paymentMethod].total_payments | currencyFormat }}</b-td>
                    <b-td class="cash-in">{{ single[paymentMethod].cash_in | currencyFormat }}
                    </b-td>
                    <b-td>{{ single[paymentMethod].refunds | currencyFormat }}</b-td>
                    <b-td>{{ single[paymentMethod].petty_taken | currencyFormat }}</b-td>
                    <b-td>{{ single[paymentMethod].expected_out | currencyFormat }}</b-td>
                    <b-td>{{ single[paymentMethod].actual_out | currencyFormat }}</b-td>
                    <b-td v-if="paymentMethod === 'Cash'">{{ single[paymentMethod].float | currencyFormat }}</b-td>
                    <b-td v-else> -</b-td>
                    <b-td>
                      {{ getDifference(single[paymentMethod].actual_out, single[paymentMethod].expected_out) | currencyFormat
                      }}
                      <small v-if="single[paymentMethod].reason"><br>{{ single[paymentMethod].reason }}</small>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="computedIsToday" class="mt-3">
      <b-col>
        <b-card class="p-3" no-body>
          <b-row>
            <b-col cols="6">
              <b-button v-b-modal.petty-taken-modal block>Petty Cash</b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                id="actual-out-btn"
                v-b-modal.actual-out-modal
                :disabled="!computedActualOut"
                block
                variant="danger">Actual Out
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="actual-out-modal" centered>
      <template #modal-header>
        <div class="d-flex flex-column">
          <h4 class="mb-1">Actual Out</h4>
          <small class="text-muted">{{ moment(date).format("DD/MM/YYYY") }}</small>
        </div>
      </template>

      <template #default="{ visible }">
        <b-list-group v-if="visible">
          <b-list-group-item
            v-for="(method, methodInd) in paymentMethods"
            :key="`actual-out-${methodInd}`">
            <div class="d-flex flex-row align-items-center">
              <p class="mb-0 flex-fill">{{ method }}</p>
              <div v-if="method === 'Cash'">
                <b-form-input v-model="float[method]" placeholder="Float"/>
              </div>
              <div class="mx-2">
                <b-form-input
                  :ref="'actual_out_' + method"
                  v-model="actualOut[method]"
                  :class="{'bg-danger text-light': disparityCheck && disparityCheck[method],'bg-success text-light':disparityCheck && !disparityCheck[method]}"
                  placeholder="Actual Out"/>
              </div>
              <div>
                <b-form-input :ref="'actual_out_' + method + '_reason'" v-model="reason[method]"
                              placeholder="Reason"></b-form-input>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="doSaveActualOut">
          <b-spinner v-if="isSaving" small></b-spinner>
          <span v-if="!isSaving">Save Actual Out</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal id="petty-taken-modal" centered>
      <template #modal-header>
        <div class="d-flex flex-column">
          <h4 class="mb-1">Petty Cash</h4>
          <small class="text-muted">{{ moment(date).format("DD/MM/YYYY") }}</small>
        </div>
      </template>

      <template #default="{ visible }">
        <b-list-group v-if="visible">
          <b-list-group-item
            v-for="(method, methodInd) in paymentMethods"
            :key="`petty-cash-${methodInd}`">
            <div class="d-flex flex-row align-items-center">
              <p class="mb-0 flex-fill">{{ method }}</p>
              <div>
                <b-form-input v-model="pettyTaken[method]" placeholder="Petty Cash"/>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="doSavePettyTaken">
          <b-spinner v-if="isSaving" small></b-spinner>
          <span v-if="!isSaving">Save Petty Cash</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal id="cash-in-modal" centered no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        <div class="d-flex flex-column">
          <h4 class="mb-1">Cash In</h4>
          <small class="text-muted">{{ moment(date).format("DD/MM/YYYY") }}</small>
        </div>
      </template>

      <template v-if="single['Cash']" #default="{ visible }">
        <b-list-group v-if="visible">
          <b-list-group-item>
            <div class="d-flex flex-row align-items-center">
              <p class="mb-0 flex-fill">Cash</p>
              <div>
                <b-form-input v-model="single['Cash'].cash_in" placeholder="Cash In"/>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </template>

      <template #modal-footer>
        <b-button variant="primary" @click="doCashIn">
          <b-spinner v-if="isSaving" small></b-spinner>
          <span v-if="!isSaving">Cash In</span>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import currentUser from "../../mixins/currentUser";
import momentMixin from "../../mixins/momentMixin";
import {mapActions, mapGetters} from "vuex";

export default {
  mixins: [currentUser, momentMixin],
  created() {
    this.date = this.moment().toDate();
    this.doDataFetch();

    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      switch (modalId) {
        case "petty-taken-modal":
          this.pettyTaken = {
            Cash: this.single["Cash"].petty_taken,
            Cheque: this.single["Cheque"].petty_taken,
            Debit: this.single["Debit"].petty_taken,
            BACS: this.single["BACS"].petty_taken,
            Finance: this.single["Finance"].petty_taken
          };
          break;
      }
    });
  },
  data: () => ({
    date: null,
    paymentMethods: ["Cash", "Cheque", "Debit", "BACS", "Finance"],
    expected_out: null,
    cash_in: null,
    total_payments: null,
    previous_day_float: null,

    pettyTaken: {
      Cash: null,
      Cheque: null,
      Debit: null,
      BACS: null,
      Finance: null
    },

    float: {
      Cash: null,
      Cheque: null,
      Debit: null,
      BACS: null,
      Finance: null
    },

    actualOut: {
      Cash: null,
      Cheque: null,
      Debit: null,
      BACS: null,
      Finance: null,
    },

    reason: {
      Cash: null,
      Cheque: null,
      Debit: null,
      BACS: null,
      Finance: null,
      reason: null,
    }
  }),

  computed: {
    ...mapGetters("cash-ups", ["single", "isSaving", "isLoading"]),

    disparityCheck() {
      let disparity = [];
      this.paymentMethods.forEach((paymentMethod) => {
        disparity[paymentMethod] = !(
          this.single && parseFloat(this.actualOut[paymentMethod]) === parseFloat(this.single[paymentMethod].expected_out)
        );
      });
      return disparity;
    },

    // Cash In
    computedCashIn() {
      if (!this.computedIsToday) return false;

      return this.paymentMethods.some(method => {
        if (method === "Cash") {
          // eslint-disable-next-line no-prototype-builtins
          if (this.single.hasOwnProperty(method)) {
            return this.single[method].cash_in === null;
          }
        }
        return false;
      });
    },

    singleIsPopulated() {
      return this.paymentMethods.every(method =>
        Object.keys(this.single).includes(method)
      );
    },

    computedIsToday() {
      return this.moment(this.date).isSame(new Date(), "day");
    },

    // Actual Out
    computedActualOut() {
      if (!this.computedIsToday) return true;

      return this.paymentMethods.every(method => {
        // eslint-disable-next-line no-prototype-builtins
        if (this.single.hasOwnProperty(method)) {
          return this.single[method].actual_out === null;
        }
        return false;
      });
    }
  },
  methods: {
    ...mapActions("cash-ups", [
      "fetch",
      "saveCashIn",
      "savePettyTaken",
      "saveActualOut"
    ]),

    // Fetch Date Results.
    doDataFetch() {
      return this.fetch(this.date).then(response => {
        /**
         * Sets the cash in value to the cash-out from the previous day (if provided from the server)
         */
        this.paymentMethods.forEach(paymentMethod => {
          if (this.single[paymentMethod].cash_in === null)
            this.single[paymentMethod].cash_in = this.single[
              paymentMethod
              ].previous_day_float;
        });
        return response;
      });
    },

    doCashIn() {
      this.saveCashIn(this.single).then(() => {
        if (!this.computedCashIn) {
          this.doDataFetch().then(() => {
            this.$bvModal.hide("cash-in-modal");
          });
        }
      });
    },

    doSavePettyTaken() {
      let data = {};
      this.paymentMethods.forEach(method => {
        data[method] = {
          petty_taken: this.pettyTaken[method],
          date: this.date
        };
      });

      this.savePettyTaken(data).then(() => {
        this.doDataFetch().then(() => {
          this.$bvModal.hide("petty-taken-modal");
        });
      });
    },

    doSaveActualOut() {
      let data = {};
      this.paymentMethods.forEach(method => {
        data[method] = {
          reason: this.reason[method],
          actual_out: this.actualOut[method],
          float: this.float[method],
          date: this.date
        };
      });

      this.saveActualOut(data).then(() => {
        this.doDataFetch().then(() => {
          this.$bvModal.hide("actual-out-modal");
        });
      });
    },

    getDifference(actual, expected) {
      return actual - expected;
    },

    tableVariationStatus(paymentMethod) {
      if (this.single[paymentMethod].actual_out === null) return;
      if (this.single[paymentMethod].actual_out === this.single[paymentMethod].expected_out) {
        return "success";
      }
      if (
        this.single[paymentMethod].actual_out < this.single[paymentMethod].expected_out ||
        this.single[paymentMethod].actual_out > this.single[paymentMethod].expected_out
      )
        return "danger";
      return "warning";
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  },
  watch: {
    computedCashIn(v) {
      if (v) this.$bvModal.show("cash-in-modal");
    }
  }
};
</script>

<style>
.modal-dialog {
  max-width: 100%;
  margin: 0 1rem;
}

.bg-danger::placeholder {
  color: white !important;
}
</style>
